<template>
  <div class="h-100">
    <be-tabs
      v-if="!fullscreen"
      v-model="activeTab"
      :options="tabs"
      translation-scope="components.documents.single_document.tabs"
    >
      <template #content-right>
        <div>
          <send-e-signatures-button
            v-if="document.policy.edit"
            :doc="document"
          />

          <be-button
            v-if="document.file_content_type != 'application/pdf'"
            :href="`${document.paths.base}/download?disposition=attachment`"
            variant="outline-secondary"
            icon="fa-arrow-down-to-bracket"
            class="mt-2 mt-lg-0"
          >
            {{ $t("components.documents.single_document.download_original") }}
          </be-button>

          <be-button
            v-if="document.pdf_url"
            :href="`${document.paths.base}/download.pdf?disposition=attachment`"
            variant="outline-secondary"
            icon="fa-arrow-down-to-bracket"
            class="mt-2 mt-lg-0"
          >
            {{ $t("components.documents.single_document.download_pdf") }}
          </be-button>
        </div>
      </template>
    </be-tabs>

    <div
      v-if="previewable && activeTab === 'preview'"
      :class="`${fullscreen ? 'h-100' : 'card'}`"
    >
      <div :class="`${fullscreen ? 'h-100' : 'card'}`">
        <be-file-viewer
          class="h-100"
          :file-url="document.preview.url"
          :file-type="document.preview.content_type"
        />
      </div>
    </div>

    <div v-if="activeTab === 'metadata'" class="card">
      <div class="card-body">
        <document-meta-data :document="document" />
      </div>
    </div>

    <div v-if="activeTab === 'comments'" class="card">
      <div class="card-body p-0">
        <document-comments
          :document="document"
          :comments="comments"
          :loading="loadingComments"
          @comment-added="handleCommentAdded"
        />
      </div>
    </div>

    <document-signature-case
      v-if="showSignatures && activeTab === 'signatures'"
      :document="document"
      :override-title="
        $t('components.documents.single_document.tabs.signatures')
      "
      tab="single-document"
    />
  </div>
</template>

<script>
import DocumentComments from "@/components/documents/DocumentComments.vue";
import DocumentMetaData from "@/components/documents/DocumentMetaData.vue";
import DocumentSignatureCase from "@/components/documents/DocumentSignatureCase.vue";
import SendESignaturesButton from "@/components/documents/SendESignaturesButton.vue";

import { contentTypeIsSupported } from "@/utils/file-preview";

export default {
  components: {
    DocumentMetaData,
    DocumentComments,
    DocumentSignatureCase,
    SendESignaturesButton,
  },

  props: {
    document: {
      type: Object,
      default: () => ({}),
    },

    fullscreen: {
      type: Boolean,
      default: false,
    },

    showSignatures: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const previewable = contentTypeIsSupported(
      this.document.preview.content_type
    );

    return {
      previewable,
      activeTab: previewable ? "preview" : "metadata",
      comments: [],
      loadingComments: false,
    };
  },

  computed: {
    tabs() {
      return [
        { value: "preview", enabled: this.previewable },
        { value: "metadata", enabled: true },

        {
          value: "comments",
          count: this.comments.length,
          enabled: this.document.company_type === "Company",
        },

        {
          value: "signatures",

          enabled:
            this.showSignatures && this.document.company_type === "Company",
        },
      ].filter((tab) => tab.enabled);
    },
  },

  async mounted() {
    await this.fetchComments();
  },

  methods: {
    async fetchComments() {
      this.loadingComments = true;
      try {
        const { data: comments } = await axios.get(
          `${this.document.paths.base}/comments`
        );
        this.comments = comments;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loadingComments = false;
      }
    },

    handleCommentAdded(comment) {
      this.comments.push(comment);
    },
  },
};
</script>
