<template>
  <be-badge v-if="inquiry.status" :variant="inquiryStatusColor">
    {{ $t(`views.companies.inquiries.inquiry.${inquiry.status}`) }}
  </be-badge>

  <be-badge v-else variant="info">
    {{ $t(`views.companies.inquiries.inquiry.pending`) }}
  </be-badge>
</template>

<script>
export default {
  props: {
    inquiry: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    inquiryStatusColor() {
      const STATUS_COLORS = {
        approved: "success",
        rejected: "danger",
        postponed: "info",
        completed: "success",
      };

      return STATUS_COLORS[this.inquiry.status] || "secondary";
    },
  },
};
</script>
