<template>
  <div v-if="subtitle" v-text="subtitle" />
</template>

<script>
export default {
  props: {
    userId: {
      type: Number,
      required: false,
      default: null,
    },

    membership: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    localMembership() {
      return this.membership || this.fromUserId;
    },

    fromUserId() {
      return this.$store.getters["company/membershipByUserId"](this.userId);
    },

    membershipFunction() {
      if (!this.localMembership?.function) {
        return null;
      }

      if (this.localMembership.company_type === "AdminCompany") {
        return this.$t(`models.membership.functions.external`);
      }

      return this.$t(
        `models.membership.functions.${this.localMembership.function}`
      );
    },

    membershipTitle() {
      return this.localMembership?.title || null;
    },

    membershipRole() {
      if (!this.localMembership?.role) {
        return null;
      }

      return this.$t(`models.membership.roles.${this.localMembership.role}`);
    },

    subtitle() {
      if (!this.localMembership) {
        return null;
      }

      if (this.localMembership.via) {
        return this.localMembership.via;
      }

      const parts = [];
      if (this.$theme.show_membership_functions && this.membershipFunction) {
        parts.push(this.membershipFunction);
      }

      if (this.membershipTitle) {
        parts.push(this.membershipTitle);
      }

      if (this.$theme.show_membership_roles && this.membershipRole) {
        parts.push(this.membershipRole);
      }

      return parts.join(" • ");
    },
  },
};
</script>
