/*
 * Logs an error to the console and optionally to Sentry
 * @param {Object} error - The error object
 * @param {Object} options - Options for the error
 * @param {String} options.message - The error message
 * @param {String} options.errorLevel - The error level
 * @param {Boolean} options.sentryLog - Whether to log to Sentry
 * @param {Boolean} options.consoleLog - Whether to log to the console
 */
const handleError = (error, options = {}) => {
  const {
    message = null,
    errorLevel = "error",
    sentryLog = true,
    consoleLog = true,
    ignoreStatuses = [422, 404, 403, 401],
  } = options;

  if (consoleLog) {
    if (message) {
      console[errorLevel](message, error);
    } else {
      console[errorLevel](error);
    }
  }

  const status = error.response?.status || error.status;

  if (sentryLog && !ignoreStatuses.includes(status) && window.Sentry) {
    window.Sentry.captureException(error);
  }
};

export { handleError };
