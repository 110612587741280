import { EventBus } from "@/event-bus";
export default {
  data() {
    return {
      currentUserLoading: false,
    };
  },

  channels: {
    CompaniesSignaturesChannel: {
      connected() {},

      rejected() {
        console.warn("CompaniesSignaturesChannel rejected");
      },

      received(response) {
        const { signature } = response;

        if (signature) {
          EventBus.emit("signature-updated", signature);

          // Set status for current user
          if (signature.user_id === this.$currentUser.id) {
            this.currentUserLoading = false;
          }
        }
      },

      disconnected() {},
    },
  },

  mounted() {
    if (this.$currentCompany) {
      this.$cable.subscribe(
        {
          channel: "Companies::SignaturesChannel",
          company_id: this.$currentCompany.id,
        },
        "CompaniesSignaturesChannel"
      );
    }
  },

  computed: {
    documentCurrentUserLoading() {
      return this.currentUserLoading;
    },
  },

  methods: {
    documentPrepareSignature(document, signature) {
      return {
        id: signature.id,
        user_id: signature.user_id,
        name: signature.user_name,
        signed_at: signature.signed_at,
        requested_at: signature.created_at,
        reminded_at: this.documentSignatureRemindedAt(signature),
        rejected_at: signature.rejected_at,
        rejection_message: signature.rejection_message,
        remindable: signature.remindable && signature.policy?.remind,
        sub_name: signature.user_id ? null : signature.email,
        path: document.paths.sign,
      };
    },

    // `reminded_at` should not be triggered if notified_at was only set on creation.
    documentSignatureRemindedAt(signature) {
      if (!signature.notified_at) {
        return null;
      }

      const notifiedAt = new Date(signature.notified_at);
      const createdAt = new Date(signature.created_at);

      // If notified_at is within 30 s och created_at we skip it
      // We create the signature and create the Assently case before
      // we set signature.notified_at.
      const thirtySeconds = 30 * 1000;
      if (Math.abs(notifiedAt - createdAt) < thirtySeconds) {
        return null;
      }

      return signature.notified_at;
    },

    async sendDocumentSignatureReminder(signature) {
      try {
        const { data } = await axios.post(
          this.url(`/documents/signatures/${signature.id}/reminder`)
        );

        EventBus.emit("signature-updated", data);
      } catch (error) {
        this.handleError(error);
      }
    },

    checkDocumentCurrentUser() {
      const waitTenSeconds = 10 * 1000;
      if (!this.currentUserLoading) {
        this.currentUserLoading = true;

        setTimeout(() => {
          // If the status is still loading after 10 seconds we set it to false
          // so the `sign` button will be visible again.
          this.currentUserLoading = false;
        }, waitTenSeconds);
      }
    },
  },
};
