import axios from "axios";
import { format } from "date-fns";
import { url } from "@/url-helpers";
import { handleError } from "@/utils/error-handling";

export const state = {
  tasks: [],
  my_tasks: [],
  last_update: null,
};

export const getters = {
  getTasks: (state) => state.tasks,

  getMyTasks: (state) => state.my_tasks,

  getCompanyTasks: (state, getters, { company: { company: currentCompany } }) =>
    state.tasks.filter((t) => t.company_id === currentCompany.id),

  getTask: (state) => (task) => state.tasks.find((t) => t === task),

  filterTasks: (state, getters) => (options) => {
    let filteredTasks = state.tasks;

    if ("userId" in options) {
      filteredTasks = getters.filterByUser(filteredTasks, options.userId);
    }

    if ("companyId" in options && options.companyId !== null) {
      filteredTasks = filteredTasks.filter(
        (task) => task.company_id === options.companyId
      );
    }

    if ("projectId" in options) {
      filteredTasks = getters.filterByProject(filteredTasks, options.projectId);
    }

    if (options.dueAtStart) {
      filteredTasks = getters.filterByDueAtStart(
        filteredTasks,
        options.dueAtStart
      );
    }

    if (options.dueAtEnd) {
      filteredTasks = getters.filterByDueAtEnd(filteredTasks, options.dueAtEnd);
    }

    if ("doneAt" in options) {
      filteredTasks = getters.filterByDoneAt(filteredTasks, options.doneAt);
    }

    if ("archivedAt" in options) {
      filteredTasks = getters.filterByArchivedAt(
        filteredTasks,
        options.archivedAt
      );
    }

    return filteredTasks;
  },

  filterByUser: () => (filterableTasks, user_id) => {
    return filterableTasks.filter((task) => {
      return task.user_id === user_id;
    });
  },

  filterByProject: () => (filterableTasks, project_id) => {
    return filterableTasks.filter((task) => {
      return task.project_id === project_id;
    });
  },

  filterByDueAtStart: () => (filterableTasks, dueAtStart) => {
    return filterableTasks.filter((task) => {
      if (task.due_at) {
        return (
          format(new Date(task.due_at), "yyyy-MM-dd") >=
          format(new Date(dueAtStart), "yyyy-MM-dd")
        );
      }
      return false;
    });
  },

  filterByDueAtEnd: () => (filterableTasks, dueAtEnd) => {
    return filterableTasks.filter((task) => {
      if (task.due_at) {
        return (
          format(new Date(task.due_at), "yyyy-MM-dd") <=
          format(new Date(dueAtEnd), "yyyy-MM-dd")
        );
      }
      return false;
    });
  },

  filterByDoneAt: () => (filterableTasks, doneAt) =>
    filterableTasks.filter((task) => !!task.done_at == doneAt),

  filterByArchivedAt: () => (filterableTasks, archivedAt) =>
    filterableTasks.filter((task) => !!task.archived_at == archivedAt),
};

export const actions = {
  reset(context) {
    context.commit("reset");
  },

  async syncMyTasks({ state, dispatch }) {
    try {
      const {
        data: { last_update },
      } = await axios.get(url("/my_tasks/last_update"));

      if (state.last_update !== last_update) {
        return dispatch("fetchMyTasks");
      }
    } catch (error) {
      handleError(error);
    }
  },

  async fetchMyTasks(context) {
    try {
      const {
        data: { tasks, updated_at },
      } = await axios.get(url("/my_tasks/"));
      context.dispatch("updateMyTasks", tasks);
      context.commit("setLastUpdate", updated_at);
    } catch (error) {
      handleError(error);
    }
  },

  updateMyTasks({ commit, getters, rootGetters }, tasks) {
    if (tasks) {
      commit("setMyTasks", tasks);
    } else {
      const userId = rootGetters["current_user/getUser"].id;
      const companyId = rootGetters["company/getCompany"].id;
      const myTasks = getters.filterTasks({
        userId: userId,
        companyId: companyId || null,
        doneAt: false,
        archivedAt: false,
      });
      commit("setMyTasks", myTasks);
    }
  },

  async createTask(context, task) {
    const { data } = await axios.post(
      url("/tasks", { nanoid: task.company_id }),
      {
        task: task,
      }
    );

    context.commit("addTask", data);

    // Update My Tasks with potential updates
    context.dispatch("updateMyTasks");
  },

  async updateTask(context, task) {
    await axios
      .patch(url(`/tasks/${task.id}`, { nanoid: task.company_id }), {
        task: task,
      })
      .then((response) => {
        context.commit("updateTask", response.data);

        // Update My Tasks with potential updates
        context.dispatch("updateMyTasks");
      });
  },

  markAsDone(context, task) {
    axios
      .patch(
        url(`/tasks/${task.id}/done`, {
          nanoid: task.company_id,
        }),
        {
          task: task,
        }
      )
      .then((response) => {
        context.commit("updateTask", response.data);

        // Update My Tasks with potential updates
        context.dispatch("updateMyTasks");
      });
  },

  markAsNotDone(context, task) {
    axios
      .patch(
        url(`/tasks/${task.id}/not_done`, {
          nanoid: task.company_id,
        }),
        {
          task: task,
        }
      )
      .then((response) => {
        context.commit("updateTask", response.data);

        // Update My Tasks with potential updates
        context.dispatch("updateMyTasks");
      });
  },

  archive(context, task) {
    axios
      .patch(
        url(`/tasks/${task.id}/archive`, {
          nanoid: task.company_id,
        }),
        {
          task: task,
        }
      )
      .then((response) => {
        context.commit("updateTask", response.data);

        // Update My Tasks with potential updates
        context.dispatch("updateMyTasks");
      });
  },

  restore(context, task) {
    axios
      .patch(
        url(`/tasks/${task.id}/restore`, {
          nanoid: task.company_id,
        }),
        {
          task: task,
        }
      )
      .then((response) => {
        context.commit("updateTask", response.data);

        // Update My Tasks with potential updates
        context.dispatch("updateMyTasks");
      });
  },

  destroy(context, task) {
    axios
      .delete(url(`/tasks/${task.id}/`, { nanoid: task.company_id }), {
        task: task,
      })
      .then(() => {
        context.commit("destroyTask", task.id);

        // Update My Tasks with potential updates
        context.dispatch("updateMyTasks");
      });
  },
};

export const mutations = {
  addTask(state, task) {
    state.tasks.push(task);
    state.updatedAt = task.updated_at;
  },

  setTasks(state, tasks) {
    state.tasks = tasks;
    return true;
  },

  setMyTasks(state, tasks) {
    state.my_tasks = tasks;
  },

  updateTask(state, data) {
    let index = state.tasks.findIndex((task) => task.id == data.id);

    if (index > -1) {
      state.tasks[index] = data;
    } else {
      state.tasks.push(data);
    }

    state.updatedAt = data.updated_at;
  },

  destroyTask(state, id) {
    let index = state.tasks.findIndex((task) => task.id == id);
    if (index > -1) {
      state.tasks.splice(index, 1);
    }
  },

  setLastUpdate(state, updatedAt) {
    state.last_update = updatedAt;
  },
};

export default {
  namespaced: true,
  sessionCached: true,
  state,
  getters,
  actions,
  mutations,
};
