import { mapGetters } from "vuex";

/**
 * Shortcuts to check if editor is in agenda or minutes mode
 */
export default {
  computed: {
    ...mapGetters({
      materialType: "material/editorType",
      meeting: "material/meeting",
      material: "material/material",
    }),

    isAgenda() {
      return this.materialType === "agenda";
    },

    isMinutes() {
      return this.materialType === "minutes";
    },

    isTemplate() {
      return this.materialType === "template";
    },

    isSealed() {
      return this.material.sealed;
    },

    currentState() {
      return this.meeting.process.active_state;
    },

    inReview() {
      return this.meeting.process.active_state == "review";
    },

    minutesReviewStarted() {
      return this.material.id && this.material.secretary_done;
    },
  },
};
