import axios from "axios";
import { handleError } from "@/utils/error-handling";

// This store contains settings/data set to persist between page navigations.
// It clears when user either leaves the site or closes the browser.

const initialState = {
  ui: {
    initialized: false,
    activityLogOpen: false,
    collapsedSideNav: false,
    currentActivityLogTab: "recent-activities", // my-tasks
  },

  activities: [],
  activitiesInitialized: false,

  releaseNotes: {
    data: [],
    timestamp: null,
  },
};

export const state = () => ({
  ...initialState,
});

export const getters = {
  activityLogOpen: (state) => state.ui.activityLogOpen,
  collapsedSideNav: (state) => state.ui.collapsedSideNav,
  haveSetDefaultUI: (state) => state.ui.initialized,
  currentActivityLogTab: (state) => state.ui.currentActivityLogTab,
  getActivities: (state) => state.activities,

  getActivitiesForCompany: (state) => (companyId) =>
    state.activities.filter((activity) => activity.company_id == companyId),

  activityInitialized: (state) => state.activitiesInitialized,
  getReleaseNotes: (state) => state.releaseNotes.data,
};

export const actions = {
  async reset(context) {
    context.commit("reset");
    await context.dispatch("setDefaultUI");
    context.dispatch("syncActivities");
  },

  setDefaultUI(context) {
    context.commit("setInitialUI");
  },

  expandSideNav(context) {
    context.commit("expandSideNav");
  },

  collapseSideNav(context) {
    context.commit("collapseSideNav");
  },

  openActivityLog(context) {
    context.commit("openActivityLog");
  },

  closeActivityLog(context) {
    context.commit("closeActivityLog");
  },

  toggleActivityLogTab({ state, commit }, tab) {
    const {
      ui: { activityLogOpen: isOpen, currentActivityLogTab: currentTab },
    } = state;

    if (currentTab !== tab) {
      commit("setActivityLogTab", tab);
      return !isOpen ? commit("openActivityLog") : null;
    }

    isOpen ? commit("closeActivityLog") : commit("openActivityLog");
  },

  setInitialActivities(context, activities) {
    // We have to reverse here so that the oldest new activity
    // can be matched against the latest activity in the saved list.
    // This makes sure that we don't have multiple similar activities
    // listed beside eachother in the list.
    //
    // `pushActivity` handles the logic for this.
    if (Array.isArray(activities)) {
      activities.reverse().forEach((activity) => {
        context.dispatch("pushActivity", activity);
      });
      context.commit("setActivitiesAsInitialized");
    }
  },

  pushActivity(context, payload) {
    context.commit("pushActivity", payload);
  },

  async syncActivities({ state, dispatch }, payload) {
    let lastActivity = state.activities[0];

    let url = "/home/last_activity";
    if (payload?.companyId) {
      url = `/companies/${payload.companyId}/activities/latest`;

      lastActivity = state.activities
        .filter((activity) => activity.company_id == payload.companyId)
        .sort((a, z) => (a.created_at < z.created_at ? 1 : -1))[0];
    }

    try {
      const { data } = await axios.get(url);

      if (data.id !== lastActivity?.id) {
        return dispatch("fetchActivities", payload);
      }
    } catch (error) {
      handleError(error, { message: "Could not check activities sync" });
    }
  },

  async fetchActivities(context, payload) {
    let url = "/home/recent_activities";
    if (payload?.companyId) {
      url = `/companies/${payload.companyId}/activities`;
    }

    try {
      const { data: activities } = await axios.get(url);

      context.dispatch("setInitialActivities", activities);
    } catch (error) {
      handleError(error, { message: "Could not fetch activities" });
    }
  },

  async fetchReleaseNotes(context, force = false) {
    const { timestamp } = context.state.releaseNotes;

    const interval = 60 * 60 * 1000; // 1 hour

    if (!force && timestamp && new Date() - new Date(timestamp) < interval)
      return;

    try {
      let { data: releaseNotes } = await axios.get("/release_notes");

      // If the response is not an array, set it to an empty array
      // This could potentially happen if the user is not authenticated
      if (!(releaseNotes instanceof Array)) {
        releaseNotes = [];
      }

      context.commit("setReleaseNotes", releaseNotes);
    } catch (error) {
      handleError(error, { message: "Could not fetch release notes" });
    }
  },

  async markReleaseNotesAsRead(context, id) {
    // Axios call to mark as read
    try {
      await axios.post(`/release_notes/${id}/reading`);

      context.commit("setReleaseNoteAsRead", id);
    } catch (error) {
      handleError(error, { message: "Could not mark release note as read" });
    }
  },
};

export const mutations = {
  reset(state) {
    Object.assign(state, { ...initialState });
  },

  setInitialUI(state) {
    state.ui.initialized = true;
  },

  expandSideNav(state) {
    state.ui.collapsedSideNav = false;
  },

  collapseSideNav(state) {
    state.ui.collapsedSideNav = true;
  },

  openActivityLog(state) {
    state.ui.activityLogOpen = true;
  },

  closeActivityLog(state) {
    state.ui.activityLogOpen = false;
  },

  setActivityLogTab(state, tabName) {
    state.ui.currentActivityLogTab = tabName;
  },

  setActivitiesAsInitialized(state) {
    state.activitiesInitialized = true;
  },

  pushActivity(state, activity) {
    // Make sure that we don't cache duplicates
    const idx = state.activities.findIndex(
      (_activity) => _activity.id === activity.id
    );

    if (idx > 0) {
      return;
    }

    // Make sure that we don't cache activities that are already recently listed
    const latestActivity = state.activities.filter(
      (_activity) => _activity.company_id == activity.company_id
    )[0];

    if (
      latestActivity?.key == activity.key &&
      latestActivity?.owner_id == activity.owner_id &&
      latestActivity?.trackable_id == activity.trackable_id &&
      latestActivity?.trackable_type == activity.trackable_type
    ) {
      return;
    }

    state.activities.unshift(activity);

    state.activities = state.activities.sort((a, z) =>
      a.created_at < z.created_at ? 1 : -1
    );
  },

  setReleaseNotes(state, releaseNotes) {
    state.releaseNotes.data = releaseNotes || [];
    state.releaseNotes.timestamp = new Date();
  },

  setReleaseNoteAsRead(state, id) {
    const idx = state.releaseNotes.data.findIndex(
      (releaseNote) => releaseNote.id === id
    );

    if (idx > 0) {
      state.releaseNotes.data[idx].read = true;
    }
  },
};

export default {
  namespaced: true,
  sessionCached: true,
  state,
  getters,
  actions,
  mutations,
};
