import { url } from "@/url-helpers";
import cloneDeep from "lodash/cloneDeep";
import { handleError } from "@/utils/error-handling";

export const state = {
  loadedMemberships: false,
  loadedNewMembership: false,
  memberships: [],
  invitations: [],
  newMembership: {},
  loading: false,
};

export const getters = {
  getMemberships: (state) =>
    state.memberships.filter((membership) => !membership.deleted),

  getInactiveMemberships: (state) =>
    state.memberships.filter((membership) => membership.deleted),

  getUser: (state) => (userId) =>
    state.memberships.find((membership) => membership.user_id == userId)?.user,

  getUsers: (state) => state.memberships.map((membership) => membership.user),

  getAllMemberships: (state) => state.memberships,
  invitations: (state) => state.invitations,

  unsentInvitations: (state) =>
    state.invitations.filter((invitation) => !invitation.id),

  loaded: (state) => state.loadedMemberships,
  loadedNew: (state) => state.loadedNewMembership,
  newMembership: (state) => state.newMembership,
  loading: (state) => state.loading,
};

export const actions = {
  async doLoading(context, loadingRequest) {
    await context.dispatch("startLoading");
    let response = {};

    try {
      response = await loadingRequest();
    } finally {
      await context.dispatch("stopLoading");
    }

    return response;
  },

  async fetchMemberships(context) {
    await context.dispatch("doLoading", async () => {
      const { data } = await axios.get(url("/memberships"));

      context.commit("setMemberships", data);
    });
  },

  async loadNewMembership(context) {
    if (
      context.getters.loadedNew == false ||
      context.getters.getNewMembership == null
    ) {
      await context.dispatch("doLoading", async () => {
        const { data } = await axios.get(url("/memberships/new"));

        context.commit("loadNewMembership", data);
      });
    }
  },

  async validateMembership(context, membership) {
    return await context.dispatch("doLoading", async () => {
      const { data } = await axios.post(url(`/memberships/validations`), {
        membership,
      });

      return data;
    });
  },

  async createMembership(context, membership) {
    return await context.dispatch("doLoading", async () => {
      const { data } = await axios.post(url("/memberships"), {
        membership,
      });
      await context.commit("addMembership", data);
      context.commit("updateInvitation", data);

      return data;
    });
  },

  async updateMembership(context, membership) {
    return await context.dispatch("doLoading", async () => {
      const { data } = await axios.patch(url(`/memberships/${membership.id}`), {
        membership,
      });

      context.commit("updateMembership", data);

      return data;
    });
  },

  async destroyMembership(context, membership) {
    return await context.dispatch("doLoading", async () => {
      const { data } = await axios.delete(url(`memberships/${membership.id}`));

      // Use update since also deleted memberships are included.
      context.commit("updateMembership", data);
    });
  },

  async reactivateMembership(context, membership) {
    return await context.dispatch("doLoading", async () => {
      const { data } = await axios.post(
        `/companies/${membership.company_id}/memberships/${membership.id}/reactivations`,
        { membership }
      );

      context.commit("updateMembership", data);
    });
  },

  async reinviteMembership(context, membership) {
    return await context.dispatch("doLoading", async () => {
      const { data } = await axios.post(
        url(`/memberships/${membership.id}/reinvitations`),
        { membership }
      );

      context.commit("updateMembership", data);
    });
  },

  addInvitation(context, membership) {
    context.commit("updateInvitation", membership);
  },

  removeInvitation(context, membership) {
    context.commit("removeInvitation", membership);
  },

  async sendInvitations(context, invitationMessage) {
    const invitations = cloneDeep(context.getters.unsentInvitations);

    await context.dispatch("doLoading", async () => {
      for (const membership of invitations) {
        try {
          membership.invitation_message = invitationMessage;
          const updatedMembership = await context.dispatch(
            "createMembership",
            membership
          );
          context.commit("company/updateMembership", updatedMembership, {
            root: true,
          });
        } catch (error) {
          await context.dispatch("addInvitation", error.response.data);
          handleError(error);
        }
      }
    });
  },

  clearInvitations(context) {
    context.commit("clearInvitations");
  },

  startLoading(context) {
    context.commit("startLoading");
  },

  stopLoading(context) {
    context.commit("stopLoading");
  },
};

export const mutations = {
  addMembership(state, membership) {
    let index = state.memberships.findIndex(
      (existing) => existing.id == membership.id
    );

    if (index === -1) {
      state.memberships.push(membership);
    } else {
      state.memberships[index] = membership;
    }
  },

  setMemberships(state, memberships) {
    state.memberships = memberships;
    state.loadedMemberships = true;
  },

  loadNewMembership(state, newMembership) {
    state.newMembership = newMembership;
    state.loadedNewMembership = true;
  },

  updateMembership(state, membership) {
    let index = state.memberships.findIndex(
      (existing) => existing.id == membership.id
    );
    if (index > -1) {
      state.memberships[index] = membership;
    } else {
      console.log("Did not find membership to update!");
    }
  },

  destroyMembership(state, id) {
    let index = state.memberships.findIndex(
      (membership) => membership.id == id
    );
    if (index > -1) {
      state.memberships.splice(index, 1);
    }
  },

  updateInvitation(state, membership) {
    // Do not check email for pending invitations, it might change
    // Check email for saved memberships
    let index = state.invitations.findIndex(
      (existing) =>
        (membership.id && membership.user.email === existing.user.email) ||
        (membership.uuid && existing.uuid === membership.uuid)
    );

    if (index > -1) {
      state.invitations[index] = membership;
    } else if (Object.keys(membership["errors"])?.length == 0) {
      state.invitations.push(membership);
    }
  },

  removeInvitation(state, membership) {
    let index = state.invitations.findIndex(
      (existing) => existing.user.email == membership.user.email
    );
    if (index > -1) {
      state.invitations.splice(index, 1);
    }
  },

  clearInvitations(state) {
    state.invitations = [];
  },

  startLoading(state) {
    state.loading = true;
  },

  stopLoading(state) {
    state.loading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
