import { url } from "@/url-helpers";
import { handleError } from "@/utils/error-handling";
import axios from "axios";

const ROOT_ID = null;

export const state = () => ({
  currentFolderId: ROOT_ID,
  documents: [],
  folders: [],
  highlightedFolder: null,
});

export const getters = {
  getCurrentFolder: (state) => {
    if (state.currentFolderId != ROOT_ID) {
      const folder = state.folders.find(
        (folder) => folder.id === state.currentFolderId
      );

      if (folder) {
        return folder;
      }
    }

    return {
      id: null,
      root: true,
    };
  },

  getDocuments: (state) => state.documents,

  getDocumentsInFolder: (state) => {
    return state.documents.filter(
      (document) => document.folder_id == state.currentFolderId
    );
  },

  getFolders: (state) => {
    return state.folders.filter(
      (folder) => folder.parent_id == state.currentFolderId
    );
  },

  getHighlightedFolder: (state) => state.highlightedFolder,
};

export const actions = {
  setCurrentFolder(context, folder) {
    if (folder?.id && folder?.title) {
      context.commit("setFolder", folder);
      context.commit("setCurrentFolderId", folder.id);
    } else {
      context.commit("setCurrentFolderId", ROOT_ID);
    }
  },

  addDocument(context, doc) {
    if (doc.deleted_at) {
      context.commit("deleteDocument", doc);
    } else {
      context.commit("setDocument", doc);
    }
  },

  addFolder(context, folder) {
    context.commit("setFolder", folder);
  },

  async selectFolder(context, folder) {
    let folderUrl;
    if (folder?.id) {
      folderUrl = url(`/documents/folders/${folder.id}`);
      context.commit("setCurrentFolderId", folder?.id);
    } else {
      folderUrl = url("/documents");
      context.commit("setCurrentFolderId", ROOT_ID);
    }

    try {
      const response = await axios.get(folderUrl);
      await context.dispatch("setCurrentFolder", response.data.folder);
      response.data.folders.forEach((folder) =>
        context.commit("setFolder", folder)
      );
      response.data.documents.forEach((document) =>
        context.commit("setDocument", document)
      );
    } catch (error) {
      handleError(error);
    }

    return folderUrl;
  },

  async updateDocument(context, document) {
    try {
      const response = await axios.patch(url(`/documents/${document.id}`), {
        document,
      });
      context.commit("setDocument", response.data);
      return response;
    } catch (error) {
      if (error.response.status === 422 && error.response.data) {
        return error.response;
      } else {
        handleError(error);
      }
    }
  },

  async updateFolderUnhandled(context, folder) {
    if (folder.id) {
      try {
        const response = await axios.patch(
          url(`/documents/folders/${folder.id}`),
          {
            folder: {
              parent_id: folder.parent_id,
              title: folder.title,
            },
          }
        );
        context.commit("setFolder", response.data);
      } catch (error) {
        handleError(error);
      }
    } else {
      try {
        const response = await axios.post(url("/documents/folders"), {
          folder: {
            parent_id: folder.parent_id,
            title: folder.title,
            private: folder.private,
          },
        });
        context.commit("setFolder", response.data);
        context.commit("highlightFolder", response.data);
      } catch (error) {
        handleError(error);
      }
    }
  },

  async deleteDocument(context, doc) {
    try {
      await axios.delete(url(`/documents/${doc.id}`));
      context.commit("deleteDocument", doc);
    } catch (error) {
      handleError(error);
    }
  },

  async deleteFolder(context, folder) {
    try {
      await axios.delete(url(`/documents/folders/${folder.id}`));
      context.commit("deleteFolder", folder);
    } catch (error) {
      handleError(error);
    }
  },
};

export const mutations = {
  setCurrentFolderId(state, folderId) {
    state.currentFolderId = folderId;
  },

  setDocuments(state, documents) {
    state.documents = documents;
  },

  setFolders(state, folders) {
    state.folders = folders;
  },

  setDocument(state, document) {
    const index = state.documents.findIndex(
      (existing) => existing.id == document.id
    );

    if (index === -1) {
      state.documents.push(document);
    } else {
      state.documents[index] = document;
    }
  },

  deleteDocument(state, document) {
    const index = state.documents.findIndex(
      (existing) => existing.id == document.id
    );
    if (index > -1) {
      state.documents.splice(index, 1);
    }
  },

  deleteFolder(state, folder) {
    const index = state.folders.findIndex(
      (existing) => existing.id === folder.id
    );
    if (index > -1) {
      state.folders.splice(index, 1);
    }
  },

  setFolder(state, folder) {
    const index = state.folders.findIndex(
      (existing) => existing.id === folder.id
    );
    if (index === -1) {
      state.folders.push(folder);
    } else {
      state.folders[index] = folder;
    }
  },

  highlightFolder(state, folder) {
    state.highlightedFolder = folder;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
