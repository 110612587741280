import { formatToTimeZone } from "date-fns-timezone";
/**
 * Format time duration to human readable format
 * @param hours {number}
 * @param minutes {number}
 * @param locale {string} "sv"
 * @returns {string} "1 h 10 m"
 */
function formatTimeDuration(total_seconds, locale) {
  if (isNaN(total_seconds) || total_seconds < 0) return null;

  let minutes = Math.round(total_seconds / 60) % 60;
  let hours = Math.floor(total_seconds / (60 * 60));

  if (hours === 1 && minutes === 0) {
    return locale === "sv" ? `${hours} timme` : `${hours} hour`;
  } else if (hours > 0) {
    if (minutes === 0) {
      return locale === "sv" ? `${hours} timmar` : `${hours} hours`;
    }
    return locale === "sv"
      ? `${hours} tim ${minutes} min`
      : `${hours} h ${minutes} m`;
  } else {
    return `${minutes} min`;
  }
}

function secondsToHMS(seconds) {
  if (isNaN(seconds) || seconds < 0) return null;

  let s = seconds % 60;
  let m = Math.floor(seconds / 60) % 60;
  let h = Math.floor(seconds / (60 * 60));

  return `${pad(h)}:${pad(m)}:${pad(s)}`;
}

function secondsToHM(seconds) {
  if (isNaN(seconds) || seconds < 0) return null;

  let m = Math.round(seconds / 60) % 60;
  let h = Math.floor(seconds / (60 * 60));

  return `${pad(h)}:${pad(m)}`;
}

function HMSToSeconds(HMS) {
  if (!/^\d{1,}:\d{1,2}(:\d{1,2})?$/.test(HMS)) return null;

  const [hours, minutes, seconds] = HMS.split(":");

  return +hours * 60 * 60 + +minutes * 60 + +(seconds || 0);
}

function pad(number) {
  return number.toString().padStart(2, "0");
}

function formatDateInStockholm(value, formatName, locale) {
  let format = toDateFnsFormat(formatName, locale);

  return formatToTimeZone(value, format, {
    timeZone: "Europe/Stockholm",
  });
}

function toDateFnsFormat(name, locale) {
  let time = "HH:mm";
  if (locale == "en") {
    time = "hh:mm a";
  }
  if (name == "date") {
    return "YYYY-MM-DD";
  } else if (name == "time") {
    return time;
  } else {
    return `YYYY-MM-DD ${time}`;
  }
}

export {
  formatTimeDuration,
  formatDateInStockholm,
  secondsToHMS,
  secondsToHM,
  HMSToSeconds,
  toDateFnsFormat,
};
