export default {
  methods: {
    // Gets image, path should be relative to app/javascript/images
    imageSrc(path) {
      const modules = import.meta.glob("@images/**/*.{png,svg,jpg,jpeg,gif}", {
        eager: true,
        import: "default",
      });
      return modules[`/images/${path}`];
    },
  },
};
